import BigNumber from "bignumber.js";
import erc20ABI from "config/abi/erc20.json";
import farmABI from "config/abi/farmV2.json";
import multicall from "utils/multicall";
import useWeb3 from "hooks/useWeb3";
import { farmsV2Config } from "config/constants/farms";
import { getAddress } from "utils/addressHelpers";
import { getFarmV2Contract } from "utils/contractHelpers";

export const fetchFarmV2UserAllowances = async (account: string) => {
  const calls = farmsV2Config.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses);
    return {
      address: lpContractAddress,
      name: "allowance",
      params: [account, getAddress(farm.farmAddress)],
    };
  });

  const rawLpAllowances = await multicall(erc20ABI, calls);
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON();
  });
  return parsedLpAllowances;
};

export const fetchFarmV2UserTokenBalances = async (account: string) => {
  const calls = farmsV2Config.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses);
    return {
      address: lpContractAddress,
      name: "balanceOf",
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls);
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });
  return parsedTokenBalances;
};

export const fetchFarmV2UserStakedBalances = async (account: string) => {
  const calls = farmsV2Config.map((farm) => {
    return {
      address: getAddress(farm.farmAddress),
      name: "balanceOf",
      params: [account],
    };
  });

  const rawStakedBalances = await multicall(farmABI, calls);
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON();
  });
  return parsedStakedBalances;
};

export const fetchFarmV2UserEarnings = async (account: string) => {
  const calls = farmsV2Config.map((farm) => {
    const farmContract = getFarmV2Contract(getAddress(farm.farmAddress));
    return farmContract.methods.calcHarvestTot(account).call();
  });
  const rawEarnings = await Promise.all(calls);
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON();
  });
  return parsedEarnings;
};
