import contracts from "./contracts";
import { FarmConfig, FarmV2Config, QuoteToken } from "./types";

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: "XPLAY",
    lpAddresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY token address
    },
    tokenSymbol: "XPLAY",
    tokenAddresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY syrup token
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY syrup token
    },
  },

  {
    pid: 1,
    lpSymbol: "XPLAY-BNB LP",
    lpAddresses: {
      97: "",
      56: "0xB4E34e0f46a8AacEB7237cCEF41ef565FD3E81FD", // lp address XPLAY-bnb
    },
    tokenSymbol: "XPLAY",
    tokenAddresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    lpSymbol: "X2P-BNB LP",
    lpAddresses: {
      97: "",
      56: "0x2db359Bd40f7547236fE0264344450AB7fB47689", // lp address x2p-bnb
    },
    tokenSymbol: "X2P",
    tokenAddresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // X2P token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    lpSymbol: "X2P",
    lpAddresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // lp address x2p-bnb
    },
    tokenSymbol: "X2P",
    tokenAddresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // X2P token address
    },
    quoteTokenSymbol: QuoteToken.X2P,
    quoteTokenAdresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // XPLAY syrup token
    },
  },
];

// 0xfE665A1bA90d190D585C4AF6aCF998a57449A372 - Xplay
// 0x87671AbEBcE03372f07740ba0aF5824718485A98 -  XPLAY_BNB_ADDRESS,
// 0x37f2dc7BffE709E4858BBF7424ceab5926635Da0 - X2P_BNB_ADDRESS,
// 0x11DfE3d52F9550C60A3FBf7BFD9BBF334E9C758A - X2P

// 0xBaB97eAebD6291a20560C52Bfc3129B1999468a6
// 0x8aEb131FCF1d976b0C500a5140903Bb822AEb6BA
// 0xb5D50b7E6083b8C6311F644f0B9AA9C9533effB4
// 0x272f008979F3dcc93314613444B741C8c621a276

export const farmsV2Config: FarmV2Config[] = [
  {
    farmAddress: {
      97: "",
      56: "0xBaB97eAebD6291a20560C52Bfc3129B1999468a6",
    },
    lpSymbol: "XPLAY",
    lpAddresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY token address
    },
    tokenSymbol: "XPLAY",
    tokenAddresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY syrup token
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY token
    },
  },
  {
    farmAddress: {
      97: "",
      56: "0xb5D50b7E6083b8C6311F644f0B9AA9C9533effB4",
    },
    lpSymbol: "XPLAY-BNB LP",
    lpAddresses: {
      97: "",
      56: "0xB4E34e0f46a8AacEB7237cCEF41ef565FD3E81FD", // lp address XPLAY-bnb
    },
    tokenSymbol: "XPLAY",
    tokenAddresses: {
      97: "",
      56: "0xB7FeEAb5ea787e83a40f185237C717597363E0d6", // XPLAY token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    farmAddress: {
      97: "",
      56: "0x272f008979F3dcc93314613444B741C8c621a276",
    },
    lpSymbol: "X2P-BNB LP",
    lpAddresses: {
      97: "",
      56: "0x2db359Bd40f7547236fE0264344450AB7fB47689", // lp address x2p-bnb
    },
    tokenSymbol: "X2P",
    tokenAddresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // X2P token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    farmAddress: {
      97: "",
      56: "0x8aEb131FCF1d976b0C500a5140903Bb822AEb6BA",
    },
    lpSymbol: "X2P",
    lpAddresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // lp address x2p
    },
    tokenSymbol: "X2P",
    tokenAddresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // X2P token address
    },
    quoteTokenSymbol: QuoteToken.X2P,
    quoteTokenAdresses: {
      97: "",
      56: "0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2", // X2P syrup token
    },
  },
];

export default farms;
