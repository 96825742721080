import React, { useEffect,useRef,useState, lazy } from "react";
import { HashRouter, Router, Redirect, Route, Switch } from "react-router-dom";
import { ResetCSS } from "@pancakeswap-libs/uikit";
import BigNumber from "bignumber.js";
import useEagerConnect from "hooks/useEagerConnect";
import {
  useFetchPriceList,
  useFetchProfile,
  useFetchPublicData,
} from "state/hooks";
import useGetDocumentTitlePrice from "./hooks/useGetDocumentTitlePrice";
import GlobalStyle from "./style/Global";
import Menu from "./components/Menu";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import ToastListener from "./components/ToastListener";
import PageLoader from "./components/PageLoader";

import GlobalCheckBullHiccupClaimStatus from "./views/Collectibles/components/GlobalCheckBullHiccupClaimStatus";
import history from "./routerHistory";

// Route-based code splitting
const Home = lazy(() => import("./views/Home"));
const FarmsComingSoon = lazy(
  () => import("./views/Farms/components/ComingSoon")
);
const Farms = lazy(() => import('./views/Farms'))
const FarmsV2 = lazy(() => import('./views/FarmsV2'))
// const Lottery = lazy(() => import('./views/Lottery'))
const NotFound = lazy(() => import("./views/NotFound"));

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null;
  }, []);

  useEagerConnect();
  useFetchPublicData();
  useFetchProfile();
  useFetchPriceList();
  useGetDocumentTitlePrice();

  const [videoPlayed,setVideoPlayed] = useState(false)
  return (
    <HashRouter>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              {/* <FarmsComingSoon/> */}
              <Home videoPlayed={videoPlayed} setVideoPlayed={setVideoPlayed}/>
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/farmsV2">
              <FarmsV2 />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      {/* <EasterEgg iterations={2} /> */}
      <ToastListener />
      <GlobalCheckBullHiccupClaimStatus />
    </HashRouter>
  );
};

export default React.memo(App);
