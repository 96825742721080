import { BigNumber, formatFixed } from "@ethersproject/bignumber";
import BigNumberJS from "bignumber.js";
import erc20 from "config/abi/erc20.json";
import masterchefABI from "config/abi/masterchef.json";
import multicall from "utils/multicall";
import { getAddress, getMasterChefAddress } from "utils/addressHelpers";
import farmsConfig, { farmsV2Config } from "config/constants/farms";

const fetchFarms = async () => {
  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      const lpAddress = getAddress(farmConfig.lpAddresses);
      const calls = [
        // Balance of token in the LP contract
        {
          address: getAddress(farmConfig.tokenAddresses),
          name: "balanceOf",
          params: [lpAddress],
        },
        // Balance of BNB token on LP contract
        {
          address: getAddress(farmConfig.quoteTokenAdresses),
          name: "balanceOf",
          params: [lpAddress],
        },
        // Balance of LP tokens in the master chef contract
        {
          address: lpAddress,
          name: "balanceOf",
          params: [getMasterChefAddress()],
        },
        // Total supply of LP tokens
        {
          address: lpAddress,
          name: "totalSupply",
        },
        // Token decimals
        {
          address: getAddress(farmConfig.tokenAddresses),
          name: "decimals",
        },
        // BNB token decimals
        {
          address: getAddress(farmConfig.quoteTokenAdresses),
          name: "decimals",
        },
      ];

      let [
        tokenBalanceLP,
        quoteTokenBalanceLP,
        lpTokenBalanceMC,
        lpTotalSupply,
        tokenDecimals,
        quoteTokenDecimals,
      ] = await multicall(erc20, calls);
      tokenBalanceLP = new BigNumberJS(tokenBalanceLP[0].toString());
      quoteTokenBalanceLP = new BigNumberJS(quoteTokenBalanceLP[0].toString());
      lpTokenBalanceMC = new BigNumberJS(lpTokenBalanceMC[0].toString());
      lpTotalSupply = new BigNumberJS(lpTotalSupply[0].toString());
      tokenDecimals = new BigNumberJS(tokenDecimals[0].toString());
      quoteTokenDecimals = new BigNumberJS(quoteTokenDecimals[0].toString());

      // TODO: Handle PID 0 (XPLAY variables)

      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = lpTokenBalanceMC.div(lpTotalSupply);

      // Total value in staking in BNB token value
      const lpTotalInQuoteToken = quoteTokenBalanceLP
        .div(new BigNumberJS(10).pow(18))
        .times(new BigNumberJS(2))
        .times(lpTokenRatio);

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      const tokenAmount = tokenBalanceLP
        .div(new BigNumberJS(10).pow(tokenDecimals))
        .times(lpTokenRatio);
      const quoteTokenAmount = quoteTokenBalanceLP
        .div(new BigNumberJS(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio);

      const [info, totalAllocPoint] = await multicall(masterchefABI, [
        {
          address: getMasterChefAddress(),
          name: "poolInfo",
          params: [farmConfig.pid],
        },
        {
          address: getMasterChefAddress(),
          name: "totalAllocPoint",
        },
      ]);
      const allocPoint = new BigNumberJS(info.allocPoint.toString());

      const poolWeight = allocPoint.div(
        new BigNumberJS(totalAllocPoint[0].toString()),
      );

      return {
        ...farmConfig,
        tokenAmount: tokenAmount.toJSON(),
        quoteTokenAmount: quoteTokenAmount.toJSON(),
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
        poolWeight: poolWeight.toJSON(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        lpTokenBalanceMC: lpTokenBalanceMC
          .div(new BigNumberJS(10).pow(quoteTokenDecimals))
          .toJSON(),
      };
    }),
  );
  return data;
};


export default fetchFarms;
