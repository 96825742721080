import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'BX2P',
    tokenAddress: '0x8A4b6747F922515ff0c4C910747247cF7e5e9801',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x8A4b6747F922515ff0c4C910747247cF7e5e9801',  // token address
    contractAddress: {
      97: '',
      56: '0x083d5710213ed4772b6E56AcE2611BC65A8Cf5f0',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: '/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
